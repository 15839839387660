const fakeSubmitButtonOnClick = (event) => {
  event.preventDefault();
  event.target.closest('form').submit();
};

const klickartPublicFakeSubmit = () => {
  const fakeSubmitButtons = document.querySelectorAll('[klickart-fake-submit-button]');

  fakeSubmitButtons.forEach((fakeSubmitButton) => {
    fakeSubmitButton.addEventListener('click', fakeSubmitButtonOnClick);
  });
};

export default klickartPublicFakeSubmit;
